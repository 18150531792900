import React from 'react'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../../utils/htmlSerializer'
import styled from 'styled-components'

const Title = styled.h3`
  &.red {
    color: ${({ theme }) => theme.colors.whyMagenta};
  }
  &.yellow {
    color: ${({ theme }) => theme.colors.whyYellow};
  }
  &.blue {
    color: ${({ theme }) => theme.colors.whyBlue};
  }
  &.orange {
    color: ${({ theme }) => theme.colors.whyOrange};
  }
  &.green {
    color: ${({ theme }) => theme.colors.whyGreen};
  }
`

const TextBlock = styled.div`
  border-radius: 15px;
  padding: 15px 30px 1px;
  margin: 50px 0 0;
  &.red {
    background-color: rgba(182, 36, 98, 0.1);
  }
  &.yellow {
    background-color: rgba(245, 185, 6, 0.1);
  }
  &.blue {
    background-color: rgba(14, 77, 128, 0.1);
  }
  &.green {
    background-color: rgba(141, 145, 64, 0.1);
  }
  &.orange {
    background-color: rgba(242, 133, 22, 0.1);
  }
  ul {
    list-style-type: disc;
    padding: 10px 0 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      padding: 15px 0 1px;
    }
  }
  li {
    margin-left: 25px;
    margin-right: 10px;
    margin-bottom: 1.5em;
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      margin-left: 40px;
      margin-right: 60px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    margin: 25px 0 0;
    padding: 20px;
    &.answer {
      &:first-of-type {
        margin-top: 250px;
      }
    }
  }
  &.green {
    em {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
    a {
      text-decoration-color: ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.blue {
    em {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
    a {
      text-decoration-color: ${({ theme }) => theme.colors.whyBlue};
    }
  }
  &.yellow {
    em {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
    a {
      text-decoration-color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
  &.red {
    em {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
    a {
      text-decoration-color: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  &.orange {
    em {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
    a {
      text-decoration-color: ${({ theme }) => theme.colors.whyOrange};
    }
  }
`

const Explore = ({ content, title, color, className }) => {
  return (
    <TextBlock className={color}>
      <Title className={color}>{title}</Title>
      <RichText render={content} htmlSerializer={htmlSerializer} />
    </TextBlock>
  )
}

export default Explore
