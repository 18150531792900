import React from 'react'
import styled from 'styled-components'

const Line = styled.hr`
  width: 100%;
  margin: 0 auto 30px;
  border: 2px solid ${({ theme }) => theme.colors.greyMedium};
  &.red {
    border: 2px solid ${({ theme }) => theme.colors.whyMagenta};
  }
  &.blue {
    border: 2px solid ${({ theme }) => theme.colors.whyBlue};
  }
  &.yellow {
    border: 2px solid ${({ theme }) => theme.colors.whyYellow};
  }
  &.green {
    border: 2px solid ${({ theme }) => theme.colors.whyGreen};
  }
  &.orange {
    border: 2px solid ${({ theme }) => theme.colors.whyOrange};
  }
`

const Divider = ({ color }) => {
  return <Line className={color} />
}

export default Divider
