import React from 'react'
import Img from 'gatsby-image'
import Carousel from "../components/secondary/Carousel"

const BlogCarousel = ({ images: { items } }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  }
  return (
    <Carousel responsive={responsive} blog>
      {items.map((item, index) => (
        <Img
          key={`image-${index}`} 
          fluid={item.image.fluid}
          alt={item.image.alt}
        />
      ))}
    </Carousel>
  )
}


export default BlogCarousel