import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ShareIcon from './ShareIcon'

const Wrapper = styled.div`
  margin: 50px 0 40px;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: flex;
    justify-content: center;
  }
  .icons {
    margin: 0;
    width: 100px;
    display: flex;
    grid-column-gap: 15px;
  }
  svg {
    margin-right: 15px;
    height: 25px;
    width: 25px;
    transition: all 0.2s ease;
    :hover {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      width: 40px !important;
      height: 40px !important;
      margin-right: 0;
    }
  }
`

const Share = ({ articleURL, articleName }) => {
  const sharePlatforms = [
    {
      name: 'Facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${articleURL}`
    },
    {
      name: 'Twitter',
      url: `https://twitter.com/intent/tweet?text=${articleName}&url=${articleURL}`
    }
  ]

  return (
    <Wrapper>
      <div className="icons">
        {sharePlatforms.map((platform, index) => {
          return (
            <ShareIcon name={platform.name} url={platform.url} key={index} />
          )
        })}
      </div>
    </Wrapper>
  )
}

Share.propTypes = {
  articleURL: PropTypes.string,
  articleName: PropTypes.string
}

export default Share
