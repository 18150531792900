import React from 'react'
import styled from 'styled-components'
import RelatedCard from '../elements/RelatedCard'
import Container from '../primary/Container'
import Carousel from '../secondary/Carousel'

const RelatedWrapper = styled.div`
  margin-top: 0;
  padding: 0;
  width: 35%;
  background-color: rgb(247, 247, 247);
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: calc(100% - 15px);
    margin-top: 25px;
  }
  .hide {
    display: none;
  }
  .related {
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: 800;
    margin-bottom: 40px;
    color: ${({ theme }) => theme.colors.whyBlue};
    max-width: 100%;
    font-size: 24px;
    margin-bottom: 15px;
    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      font-size: 20px;
    }
  }
  .container {
    margin: 0 0 0 50px;
    padding: 0;
    background: rgb(247, 247, 247);
    @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
      margin: 15px 0 0 20px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      margin: 0;
    }
  }
`
const ArticleWrap = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
`
const StyledCarousel = styled.div`
  .react-multi-carousel-dot {
    button {
      width: 9px;
      height: 9px;
    }
  }
  .react-multi-carousel-track {
    margin-bottom: 45px;
  }
`

const RelatedAnswers = ({ answers, color }) => {
  const responsive = {
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1,
      partialVisibilityGutter: 60
    }
  }
  return (
    <RelatedWrapper>
      <div className={answers.length > 0 ? '' : 'hide'}>
        <Container>
          <StyledCarousel>
            <h3 className="related">Related Answers...</h3>
            <ArticleWrap>
              <Carousel responsive={responsive} partialVisible={true}>
                {answers.map((article, index) => {
                  if (!article.answer.document) return null
                  const doc = article.answer.document
                  const title = doc.data.title
                  const category =
                    doc.data.categories[0]?.category.document.data.name
                  const image = doc.data.featured_image
                  const cat = doc.data.categories[0]?.category?.uid
                  const url =
                    cat === 'about' ? `/${doc.uid}` : `/stories/${doc.uid}`
                  return (
                    <RelatedCard
                      className="answers"
                      key={`cont-${index}`}
                      color={color}
                      title={title}
                      image={image}
                      link={url}
                    />
                  )
                })}
              </Carousel>
            </ArticleWrap>
          </StyledCarousel>
        </Container>
      </div>
    </RelatedWrapper>
  )
}

export default RelatedAnswers
