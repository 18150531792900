import React from 'react'
import PropTypes from 'prop-types'
import { Quote, Content, Banner, BlogCarousel } from '../../slices'
import Divider from './Divider'

const SliceZone = ({ slices, color }) => {
  return slices.map((slice, index) => {
    if (!slice) return null
    switch (slice.slice_type) {
      case 'quote':
        return <Quote key={index} data={slice} color={color} />
      case 'text':
        return <Content key={index} data={slice} />
      case 'carousel':
        return <BlogCarousel key={index} images={slice} />
      case 'image':
        return <Banner key={index} image={slice} />
      case 'divider':
        if (slice.primary.line)
          return <Divider key={index} color={slice.primary.highlight_color} />
      default:
        return null
    }
  })
}

SliceZone.propTypes = {
  slices: PropTypes.array
}

export default SliceZone
