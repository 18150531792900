import React from 'react'
import styled from 'styled-components'

const Title = styled.h3`
  &.orange {
    color: ${({ theme }) => theme.colors.whyOrange};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    margin-bottom: 15px !important;
  }
`

const TextBlock = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    margin-top: 25px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 0 50px 20px 0;
  }
  span {
    display: block;
    margin-bottom: 14px;
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 15px;
    }
  }
`

const Sources = ({ content }) => {
  return (
    <TextBlock>
      <Title className="orange">Sources</Title>
      {content.map((source, i) =>
        source.link?.url ? (
          <a href={source.link.url} target="blank" key={`source-${i}`}>
            <span>{source.source_name}</span>
          </a>
        ) : (
          <span key={`source-${i}`}>{source.source_name}</span>
        )
      )}
    </TextBlock>
  )
}

export default Sources
