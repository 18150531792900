import React from 'react'
import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  margin-top: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 40%;
  }
`
const Badge = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 12px;
  display: ${({ ages }) => (ages ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  color: white;
  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    width: 35px;
    height: 35px;
    margin: 0 0 7px 5px;
  }
  span {
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 1.55em;
    @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
      font-size: 10px;
      line-height: 10px;
    }
  }
  &.yellow {
    background-color: ${({ theme }) => theme.colors.darkYellow};
  }
  &.magenta {
    background-color: ${({ theme }) => theme.colors.darkMagenta};
  }
  &.blue {
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
  &.orange {
    background-color: ${({ theme }) => theme.colors.darkOrange};
  }
`
const Badges = ({ ageOne, ageTwo, ageThree, ageFour }) => {
  return (
    <Flex>
      <Badge className="yellow" ages={ageOne}>
        <span>5-7</span>
      </Badge>
      <Badge className="magenta" ages={ageTwo}>
        <span>8-10</span>
      </Badge>
      <Badge className="blue" ages={ageThree}>
        <span>11-13</span>
      </Badge>
      <Badge className="orange" ages={ageFour}>
        <span>14-15</span>
      </Badge>
    </Flex>
  )
}

export default Badges
