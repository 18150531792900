import React from 'react'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../utils/htmlSerializer'
import styled from 'styled-components'

const TextBlock = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 0;
  }
  img {
    max-width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      padding: 0 50px;
      max-width: 100%;
    }
  }
  ul {
    list-style-type: disc;
    padding: 10px 0 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      padding: 15px 0 1px;
    }
  }
  li {
    margin-left: 25px;
    margin-right: 10px;
    margin-bottom: 1.5em;
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      margin-left: 40px;
      margin-right: 60px;
    }
  }
  &.green {
    h5 {
      background-color: rgba(141, 145, 64, 0.1);
      padding: 25px;
      border-radius: 15px;
    }
    em {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
    a {
      border-bottom: 2px solid ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.blue {
    em {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
    a {
      border-bottom: 2px solid ${({ theme }) => theme.colors.whyBlue};
    }
  }
  &.yellow {
    em {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
    a {
      border-bottom: 2px solid ${({ theme }) => theme.colors.whyYellow};
    }
  }
  &.red {
    em {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
    a {
      border-bottom: 2px solid ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  &.orange {
    em {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
    a {
      border-bottom: 2px solid ${({ theme }) => theme.colors.whyOrange};
    }
  }
`

const Content = ({ data: { primary } }) => {
  return (
    <TextBlock className={primary.highlight_color}>
      <RichText render={primary.text.raw} htmlSerializer={htmlSerializer} />
    </TextBlock>
  )
}

export default Content
