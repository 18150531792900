import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 70%;
  max-width: 950px;
  background-color: rgb(247, 247, 247);
  @media (min-width: 1700px) {
    max-width: 57%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 100%;
  }
`

const ArticleContainer = ({ children }) => <Container>{children}</Container>

export default ArticleContainer
