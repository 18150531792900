import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 0 auto 35px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 0 50px;
  }
  .gatsby-image-wrapper {
    margin-bottom: 35px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
`
const Banner = ({ image: { items } }) => {
  return (
    <Wrapper>
      {items.map((item, index) => (
        <Img
          key={`image-${index}`}
          fluid={item.element.fluid}
          alt={item.element.alt}
        />
      ))}
    </Wrapper>
  )
}

Banner.defaultProps = {
  image: {
    alt: '',
    fluid: null
  }
}

Banner.propTypes = {
  image: PropTypes.object
}

export default Banner
