import React from 'react'
import { graphql } from 'gatsby'
import Share from '../components/elements/Share'
import SliceZone from '../components/page-sections/SliceZone'
import Related from '../components/page-sections/Related'
import ArticleContainer from '../components/page-sections/ArticleContainer'
import SEO from '../components/primary/SEO'
import Layout from '../components/primary/Layout'
import ArticleBanner from '../components/page-sections/NewArticleBanner'
import Explore from '../components/page-sections/Explore'
import Sources from '../components/page-sections/Sources'
import { withPreview } from 'gatsby-source-prismic'
import Img from 'gatsby-image'
import RelatedArticles from '../components/page-sections/RelatedArticles'
import useWindowSize from '../utils/useWindowSize'
import {
  ContentStyles,
  FeaturedImage,
  Line,
  Wrapper
} from '../styles/article.styles.js'
import RelatedAnswers from '../components/page-sections/MobileRelatedAnswers'
import RelatedContent from '../components/page-sections/MobileRelatedContent'

const Article = ({ data: { prismicArticle }, pageContext, location }) => {
  const data = prismicArticle.data
  const colorOption = data.categories[0]?.category?.document?.data?.color
  const color = colorOption && colorOption !== 'none' ? colorOption : 'blue'
  const title = data.title
  const category = data.categories[0]?.category?.document?.data?.name
  const subcategories = data.subcategories
  const catUid = data.categories[0]?.category?.uid
  const exploration = data.exploration.raw
  const experiment = data.experiment.raw
  const sources = data.sources[0]?.source_name ? data.sources : null
  const further = data.further_information.raw
  const explorationText =
    exploration?.length > 0 && exploration[0]?.text !== '' ? exploration : null
  const experimentText =
    experiment?.length > 0 && experiment[0]?.text !== '' ? experiment : null
  const furtherText =
    further?.length > 0 && further[0]?.text !== '' ? further : null
  const isMobile = useWindowSize() < 650
  const experimentColor =
    data.experiment_color === null || 'none' ? 'blue' : data.experiment_color
  const explorationColor =
    data.exploration_title === null || 'none' ? 'red' : data.exploration_title
  const furtherInfoColor =
    data.further_info_color === null || 'none'
      ? 'yellow'
      : data.further_info_color

  return (
    <Layout>
      <SEO
        title={`${title}`}
        description={data.meta_description || data.excerpt.text}
        keywords={data.keywords}
        image={data.featured_image.url}
      />
      {isMobile ? (
        <>
          <Wrapper className="wrapper">
            <ArticleBanner
              category={category}
              subcategories={subcategories}
              catUid={catUid}
              title={title}
              color={color}
              excerpt={data.excerpt?.text}
              featured={data.featured_image}
              ageOne={data.age_group_one}
              ageTwo={data.age_group_two}
              ageThree={data.age_group_three}
              ageFour={data.age_group_four}
            />
            <Wrapper
              className={
                data.related_content.length > 0 ||
                data.related_answers.length > 0
                  ? 'related'
                  : ''
              }
            >
              <ArticleContainer>
                {data.featured_image.fluid && (
                  <FeaturedImage>
                    <Img
                      fluid={data.featured_image.fluid}
                      alt={data.featured_image.alt}
                    />
                  </FeaturedImage>
                )}
                <ContentStyles className={color}>
                  <SliceZone slices={data.body} color={color} />
                </ContentStyles>
                {data.related_answers[0]?.answer?.document && (
                  <RelatedAnswers
                    answers={data.related_answers}
                    color={color}
                  />
                )}
                {data.related_answers[0]?.answer?.document ? (
                  <>
                    <ContentStyles className={color}>
                      {explorationText && (
                        <Explore
                          color={explorationColor}
                          content={explorationText}
                          title={
                            data.exploration_alt_title
                              ? data.exploration_alt_title
                              : 'Exploration'
                          }
                          className="answer red"
                        />
                      )}
                      {experimentText && (
                        <Explore
                          color={experimentColor}
                          content={experimentText}
                          title={
                            data.experiment_alt_title
                              ? data.experiment_alt_title
                              : 'Experiment'
                          }
                          className="answer blue"
                        />
                      )}
                      {furtherText && (
                        <Explore
                          color={furtherInfoColor}
                          content={furtherText}
                          title={
                            data.further_info_alt_title
                              ? data.further_info_alt_title
                              : 'Further Information'
                          }
                          className="answer yellow"
                        />
                      )}
                      {sources && <Sources content={data.sources} />}
                    </ContentStyles>
                  </>
                ) : (
                  <>
                    <ContentStyles className={color}>
                      {explorationText && (
                        <Explore
                          color={explorationColor}
                          content={explorationText}
                          title={
                            data.exploration_alt_title
                              ? data.exploration_alt_title
                              : 'Exploration'
                          }
                          className="red"
                        />
                      )}
                      {experimentText && (
                        <Explore
                          color={experimentColor}
                          content={experimentText}
                          title={
                            data.experiment_alt_title
                              ? data.experiment_alt_title
                              : 'Experiment'
                          }
                          className="blue"
                        />
                      )}
                      {furtherText && (
                        <Explore
                          color={furtherInfoColor}
                          content={furtherText}
                          title={
                            data.further_info_alt_title
                              ? data.further_info_alt_title
                              : 'Further Information'
                          }
                          className="yellow"
                        />
                      )}
                      {sources && <Sources content={data.sources} />}
                    </ContentStyles>
                  </>
                )}

                <Share articleURL={location.href} articleName={title} />
                {data.related_content.length > 0 && (
                  <RelatedContent
                    content={data.related_content}
                    color={color}
                  />
                )}
              </ArticleContainer>
            </Wrapper>
            {data.related_content[0]?.content?.document ? (
              <>
                {data.related_articles[0]?.element?.document && (
                  <Line className="content" />
                )}
              </>
            ) : (
              <>{data.related_articles[0]?.element?.document && <Line />}</>
            )}
          </Wrapper>
          {data.related_articles[0]?.element?.document && (
            <RelatedArticles content={data.related_articles} color={color} />
          )}
        </>
      ) : (
        <>
          <Wrapper className="wrapper">
            <ArticleBanner
              category={category}
              subcategories={subcategories}
              catUid={catUid}
              title={title}
              color={color}
              excerpt={data.excerpt?.text}
              featured={data.featured_image}
              ageOne={data.age_group_one}
              ageTwo={data.age_group_two}
              ageThree={data.age_group_three}
              ageFour={data.age_group_four}
            />
            <Wrapper
              className={
                //data.related_content.length > 0 ||
                data.related_answers.length > 0 ? 'related' : ''
              }
            >
              <ArticleContainer>
                {data.featured_image.fluid && (
                  <FeaturedImage>
                    <Img
                      fluid={data.featured_image.fluid}
                      alt={data.featured_image.alt}
                    />
                  </FeaturedImage>
                )}
                <ContentStyles className={color}>
                  <SliceZone slices={data.body} color={color} />
                  {explorationText && (
                    <Explore
                      color={explorationColor}
                      content={explorationText}
                      title={
                        data.exploration_alt_title
                          ? data.exploration_alt_title
                          : 'Exploration'
                      }
                      className="red"
                    />
                  )}
                  {experimentText && (
                    <Explore
                      color={experimentColor}
                      content={experimentText}
                      title={
                        data.experiment_alt_title
                          ? data.experiment_alt_title
                          : 'Experiment'
                      }
                      className="blue"
                    />
                  )}
                  {furtherText && (
                    <Explore
                      color={furtherInfoColor}
                      content={furtherText}
                      title={
                        data.further_info_alt_title
                          ? data.further_info_alt_title
                          : 'Further Information'
                      }
                      className="yellow"
                    />
                  )}
                  {sources && <Sources content={data.sources} />}
                </ContentStyles>
                <Share articleURL={location.href} articleName={title} />
              </ArticleContainer>
              {(data.related_answers[0]?.answer?.document ||
                data.related_content[0]?.content?.document) && (
                <Related
                  content={data.related_content}
                  answers={data.related_answers}
                  color={color}
                />
              )}
            </Wrapper>
            {data.related_articles[0]?.element?.document && <Line />}
          </Wrapper>
          {data.related_articles[0]?.element?.document && (
            <RelatedArticles content={data.related_articles} color={color} />
          )}
        </>
      )}
    </Layout>
  )
}
export default withPreview(Article)

export const articleQuery = graphql`
  query Article($uid: String) {
    prismicArticle(uid: { eq: $uid }) {
      uid
      data {
        sources {
          link {
            url
          }
          source_name
        }
        age_group_four
        age_group_one
        age_group_three
        age_group_two
        title
        experiment {
          raw
        }
        experiment_alt_title
        experiment_color
        exploration {
          raw
        }
        exploration_alt_title
        exploration_title
        further_information {
          raw
        }
        further_info_alt_title
        further_info_color
        related_articles {
          element {
            uid
            document {
              ... on PrismicArticle {
                data {
                  title
                  featured_image {
                    fluid(maxWidth: 300) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  categories {
                    category {
                      uid
                    }
                  }
                }
                uid
              }
            }
          }
        }
        related_content {
          content {
            uid
            document {
              ... on PrismicArticle {
                data {
                  title
                  featured_image {
                    fluid(maxWidth: 300) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  categories {
                    category {
                      uid
                      document {
                        ... on PrismicCategory {
                          data {
                            color
                            name
                          }
                        }
                      }
                    }
                  }
                }
                uid
              }
            }
          }
        }
        related_answers {
          answer {
            uid
            document {
              ... on PrismicArticle {
                data {
                  title
                  featured_image {
                    fluid(maxWidth: 300) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  categories {
                    category {
                      uid
                      document {
                        ... on PrismicCategory {
                          data {
                            color
                            name
                          }
                        }
                      }
                    }
                  }
                }
                uid
              }
            }
          }
        }
        keywords
        meta_description
        featured_image {
          alt
          url
          fluid(maxWidth: 900) {
            ...GatsbyPrismicImageFluid
          }
        }
        created
        body {
          ... on PrismicArticleBodyQuote {
            primary {
              text {
                raw
              }
            }
            slice_type
          }
          ... on PrismicArticleBodyText {
            primary {
              highlight_color
              text {
                raw
              }
            }
            slice_type
          }
          ... on PrismicArticleBodyImage {
            slice_type
            items {
              element {
                alt
                fluid(maxWidth: 900) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicArticleBodyDivider {
            slice_type
            id
            primary {
              highlight_color
              line
            }
          }
          ... on PrismicArticleBodyCarousel {
            slice_type
            items {
              image {
                fluid(maxWidth: 900) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
        categories {
          category {
            uid
            document {
              ... on PrismicCategory {
                data {
                  color
                  name
                }
              }
            }
          }
        }
        subcategories {
          subcategory {
            uid
            document {
              ... on PrismicSubcategory {
                data {
                  name
                }
              }
            }
          }
        }
        excerpt {
          text
        }
      }
    }
  }
`
