import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import RelatedCard from '../elements/RelatedCard'
import Container from '../primary/Container'
import { StaticImage } from 'gatsby-plugin-image'

const RelatedWrapper = styled.div`
  margin-top: 0;
  padding: 0;
  width: 32%;
  background-color: rgb(247, 247, 247);
  @media (min-width: 1700px) {
    width: 25%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .hide {
    display: none;
  }
  .related {
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: 800;
    margin-bottom: 40px;
    color: ${({ theme }) => theme.colors.whyBlue};
    max-width: 100%;
    font-size: 27px;
    margin-bottom: 12px;
  }
  .container {
    margin: 0 0 0 50px;
    padding: 0;
    background: rgb(247, 247, 247);
    @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
      margin: 15px 0 0 20px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      margin: 0;
    }
  }
`
const ArticleWrap = styled.div`
  display: flex;
  grid-row-gap: 20px;
  flex-direction: column;
  &.banners {
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      flex-direction: row;
      gap: 20px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
      flex-direction: column;
    }
  }
`
const AnswersWrapper = styled.div`
  margin: 0 0 25px 35px;
  padding: 25px 10px 10px;
  background-color: white;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    margin: 0 0 25px 20px;
    padding: 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 0 0 15px 0;
  }
  .answers {
    border-bottom: 2px solid rgba(170, 187, 204, 0.5);
    padding: 20px 5px;
    &:last-of-type {
      border-bottom: none;
    }
  }
  .answers-wrap {
    grid-row-gap: 1px;
  }
`
const Related = ({ content, color, answers }) => {
  const bannerOne = '../../images/banner1.png'
  const bannerTwo = '../../images/banner2.png'
  const bannerThree = '../../images/banner3.png'
  return (
    <RelatedWrapper>
      <div className={answers.length > 0 ? '' : 'hide'}>
        <AnswersWrapper>
          <h3 className="related">Related Answers...</h3>
          <ArticleWrap className="answers-wrap">
            {answers.map((article, index) => {
              if (!article.answer.document) return null
              const doc = article.answer.document
              const title = doc.data.title
              const image = doc.data.featured_image
              const cat = doc.data.categories[0]?.category?.uid
              const url =
                cat === 'about' ? `/${doc.uid}` : `/stories/${doc.uid}`
              return (
                <RelatedCard
                  className="answers"
                  key={`ans-${index}`}
                  color={color}
                  title={title}
                  image={image}
                  link={url}
                />
              )
            })}
          </ArticleWrap>
        </AnswersWrapper>
      </div>
      <Container>
        <h3 className="related">Don't miss...</h3>
        <ArticleWrap className="banners">
          <a href="https://www.whyzzbooks.com/" target="_blank">
            <StaticImage src={bannerOne} alt="Whyzz Books" placeholder="none" />
          </a>
          <a href="https://www.instagram.com/whyzzexplorer/" target="_blank">
            <StaticImage
              src={bannerTwo}
              alt="Whyzz Explorer"
              placeholder="none"
            />
          </a>
          <Link to="/salon-sunday">
            <StaticImage
              src={bannerThree}
              alt="Salon Sunday"
              placeholder="none"
            />
          </Link>
        </ArticleWrap>
      </Container>
    </RelatedWrapper>
  )
}

export default Related
