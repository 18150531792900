import React from 'react'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../utils/htmlSerializer'
import styled from 'styled-components'
import Quotes from "../images/quotes.inline.svg";

const StyledQuote = styled.blockquote`
  position: relative;
  margin: 45px 0;
  display: flex;
  align-items: center;
  border-top: 1px solid #799fb8;
  border-bottom: 1px solid #799fb8;
  padding: 20px 0;
  @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
    margin: 65px 0;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xl}) {
    margin: 65px 50px;
  }
  &.quote {
    p {
      font-size: 18px; 
      font-weight: 800;
      line-height:  1.3em;
      margin-bottom: .3em;
      font-family: ${({ theme }) => theme.font.heading};
      @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
        font-size: 24px; 
      }
    }
    strong {
      @media (min-width: ${({theme}) => theme.breakpoints.s}) {
        font-size: 26px; 
      }
    }
  }
  &.orange {
    strong {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
    path {
      fill: ${({ theme }) => theme.colors.whyOrange};
    }
  }
  &.red {
    strong {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
    path {
      fill: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  &.green {
    strong {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
    path {
      fill: ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.blue {
    strong {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
    path {
      fill: ${({ theme }) => theme.colors.whyBlue};
    }
  }
  &.yellow {
    strong {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
    path {
      fill: ${({ theme }) => theme.colors.whyYellow};
    }
  }
`
const QuoteWrap = styled.div`
  width: 17%;
  padding: 5px;
  @media (min-width: ${({theme}) => theme.breakpoints.xxs}) {
    padding: 10px;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
    padding: 20px;
  }
`
const TextWrap = styled.div`
  width: 83%;
  padding: 20px 10px;
  @media (min-width: ${({theme}) => theme.breakpoints.xxs}) {
    padding: 20px 20px 20px 12px;
  }
`

const Quote = ({ data: { primary }, color }) => {
  if (!primary.text.raw.length) {
    return null
  }
  return (
    <StyledQuote className={`quote ${color}`}>
      <QuoteWrap>
        <Quotes />
      </QuoteWrap>
      <TextWrap>
        <RichText render={primary.text.raw} htmlSerializer={htmlSerializer} />
      </TextWrap>
    </StyledQuote>
  )
}

export default Quote
