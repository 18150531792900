import styled from 'styled-components'

export const ContentStyles = styled.div`
  iframe {
    width: 100%;
    height: 220px;
    @media (min-width: ${({ theme }) => theme.breakpoints.xxs}) {
      height: 300px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      height: 350px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
      height: 450px;
    }
  }
  ::marker {
    color: red;
  }
  p,
  ul {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.9em;
    margin-bottom: 1.8em;
    min-height: 5px;
    color: ${({ theme }) => theme.colors.greyMedium};
    @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
      font-size: 20px;
      line-height: 1.8em;
      min-height: 34px;
    }
  }
  strong {
    font-weight: 700;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin-bottom: 1em;
    padding-top: 0.5em;
    color: ${({ theme }) => theme.colors.greyMedium};
    @media (min-width: ${({ theme }) => theme.breakpoints.xxs}) {
      margin-bottom: 1.1em;
      padding-top: 0.7em;
    }
  }
  h2,
  h3 {
    line-height: 1.25em;
  }
  h4,
  h5,
  h6 {
    line-height: 1.6em;
  }
  /* .gatsby-image-wrapper {
  margin-top: 50px;
  margin-bottom: 50px;
} */
  li::marker {
    font-size: 1.3em;
  }
  a {
    border-bottom: 2px solid ${({ theme }) => theme.colors.greyMedium};
  }
  &.orange {
    li::marker {
      color: ${({ theme }) => theme.colors.whyOrange};
    }
  }
  &.red {
    li::marker {
      color: ${({ theme }) => theme.colors.whyMagenta};
    }
  }
  &.green {
    li::marker {
      color: ${({ theme }) => theme.colors.whyGreen};
    }
  }
  &.blue {
    li::marker {
      color: ${({ theme }) => theme.colors.whyBlue};
    }
  }
  &.yellow {
    li::marker {
      color: ${({ theme }) => theme.colors.whyYellow};
    }
  }
  .carousel-wrapper {
    margin-bottom: 40px;
  }
  h2 {
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 25px;
    }
  }
  h3 {
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  p {
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 12px;
      line-height: 20px;
    }
  }
`
export const FeaturedImage = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0 auto 35px;
  order: -1;
  aspect-ratio: 16/10;
  .gatsby-image-wrapper {
    height: 100%;
  }
`
export const Line = styled.hr`
  border: 1px solid rgba(170, 187, 204, 0.5);
  margin: 0 auto;
  width: 87%;
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    &.content {
      margin-top: 200px;
    }
  }
`
export const Wrapper = styled.div`
  background-color: rgb(247, 247, 247);
  padding: 0px 100px 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 0 50px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    padding: 0 25px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 0 15px 50px;
  }
  &.related {
    flex-direction: row;
    align-items: unset;
    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      flex-direction: column;
      display: flex;
      align-items: center;
    }
  }
  &.wrapper {
    flex-direction: column;
    padding: 50px 0 0;
    align-items: unset;
  }
`
