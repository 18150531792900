import React from 'react'
import {
  FaFacebook,
  FaTwitter,
}
from 'react-icons/fa'
import PropTypes from 'prop-types'
import styled from 'styled-components';

const Facebook = styled(FaFacebook)`
  fill: rgb(41, 86, 132);
  width: 50px !important;
  height: 50px !important;
`
const Twitter = styled(FaTwitter)`
  width: 50px !important;
  height: 50px !important;
  fill: rgb(78, 150, 194);
`

const Icon = ({ name, url }) => {
  const renderIcon = () => {
    switch (name) {
      case 'Facebook':
        return (
          <Facebook />
        )
      case 'Twitter':
        return (
          <Twitter />
        )
      default:
        return null
    }
  }

  return (
      <a href={url}
      target="_blank"
      rel="noreferrer noopener"
      aria-label={name}
      title={name}>
      {renderIcon()}
      </a>
  )
}

Icon.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  style: PropTypes.object
}

export default Icon
