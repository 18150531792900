import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { BsArrowRight } from 'react-icons/bs'

const StyledCard = styled.div`
  position: relative;
  padding: 20px 15px 15px;
  margin: 0;
  min-height: 70px;
  background-color: white;
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 10px;
    border-radius: 10px;
    height: 130px;
    margin: 0 10px 0 0;
    &.answers {
      height: 100px;
    }
  }
`
const TopLayer = styled.div`
  display: flex;
  flex-direction: column;
  span,
  svg {
    color: ${({ theme }) => theme.colors.whyYellow};
  }
`
const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  h3 {
    font-size: 19px;
    color: ${({ theme }) => theme.colors.greyDark};
    font-weight: 600;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    h3 {
      font-size: 15px;
    }
  }
`
const ImageWrap = styled.div`
  height: 90px;
  width: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    width: 70px;
    height: 60px;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  padding: 0 10px 0 0;
  flex: 1;
  height: fit-content;
`
const ReadMore = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
    line-height: 6px;
    font-weight: 700;
    margin-right: 5px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    span {
      font-size: 10px;
    }
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: 20px;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    grid-column-gap: 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    grid-column-gap: 5px;
  }
`
const Category = styled.h3`
  color: #0e4d80;
  font-size: 17px;
  font-weight: 700;
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    font-size: 14px;
  }
`
const RelatedCard = ({ color, title, image, link, category, className }) => {
  return (
    <StyledCard className={className}>
      <Link to={link}>
        <TopLayer className={color}>
          <ContentWrapper>
            {image.fluid && (
              <ImageWrap>
                <Img fluid={image.fluid} alt={image.alt} />
              </ImageWrap>
            )}
            <Info>
              {category && <Category>{category}</Category>}
              <Title>
                <h3>{title}</h3>
              </Title>
              <ReadMore>
                <span>Read full article</span>
                <BsArrowRight />
              </ReadMore>
            </Info>
          </ContentWrapper>
        </TopLayer>
      </Link>
    </StyledCard>
  )
}

export default RelatedCard
