import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Container from '../primary/Container'
import Carousel from '../secondary/Carousel'
import { StaticImage } from 'gatsby-plugin-image'

const RelatedWrapper = styled.div`
  margin-top: 0;
  padding: 0;
  width: 35%;
  background-color: rgb(247, 247, 247);
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: calc(100% - 15px);
  }
  .hide {
    display: none;
  }
  .related {
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: 800;
    margin-bottom: 40px;
    color: ${({ theme }) => theme.colors.whyBlue};
    max-width: 100%;
    font-size: 24px;
    margin-bottom: 15px;
    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      font-size: 20px;
    }
  }
  .container {
    margin: 0 0 0 50px;
    padding: 0;
    background: rgb(247, 247, 247);
    @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
      margin: 15px 0 0 20px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
      margin: 0;
    }
  }
`
const ArticleWrap = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
`
const StyledCarousel = styled.div`
  li {
    padding-right: 20px;
  }
  .react-multi-carousel-dot {
    button {
      width: 9px;
      height: 9px;
    }
  }
  .react-multi-carousel-track {
    margin-bottom: 45px;
  }
`

const RelatedContent = ({ content, color }) => {
  const responsive = {
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1,
      partialVisibilityGutter: 60
    }
  }
  const bannerOne = '../../images/banner1.png'
  const bannerTwo = '../../images/banner2.png'
  const bannerThree = '../../images/banner3.png'
  return (
    <RelatedWrapper>
      <Container>
        <StyledCarousel>
          <h3 className="related">Don't miss...</h3>
          <ArticleWrap>
            <Carousel responsive={responsive} partialVisible={true}>
              <a href="https://www.whyzzbooks.com/" target="_blank">
                <StaticImage
                  src={bannerOne}
                  alt="Whyzz Books"
                  placeholder="none"
                />
              </a>
              <a
                href="https://www.instagram.com/whyzzexplorer/"
                target="_blank"
              >
                <StaticImage
                  src={bannerTwo}
                  alt="Whyzz Explorer"
                  placeholder="none"
                />
              </a>
              <Link to="/salon-sunday">
                <StaticImage
                  src={bannerThree}
                  alt="Salon Sunday"
                  placeholder="none"
                />
              </Link>
            </Carousel>
          </ArticleWrap>
        </StyledCarousel>
      </Container>
    </RelatedWrapper>
  )
}

export default RelatedContent
