import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CardWithImage from '../elements/CardWithImage'
import Container from '../primary/Container'

const Wrapper = styled.div`
  margin-top: 0;
  padding-top: 55px;
  background-color: rgb(247, 247, 247);
  .container {
    margin: 0 100px;
    padding: 0;
    @media (min-width: 1700px) {
      max-width: 77%;
      min-width: 77%;
      margin: 0 auto;
      align-self: center;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      margin: 0 50px;
    }
  }
  .related {
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: 800;
    margin-bottom: 40px;
    color: ${({ theme }) => theme.colors.whyBlue};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding-top: 80px;
  }
`

const ArticleWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  padding-bottom: 60px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    grid-row-gap: 70px;
  }
`
const RelatedArticles = ({ content, color }) => {
  return (
    <Wrapper>
      <Container width="100%">
        <h3 className="related">Read Also...</h3>
        <ArticleWrap>
          {content.map((article, index) => {
            if (!article.element.document) return null
            const doc = article.element.document
            const cat = doc.data.categories[0]?.category?.uid
            const url = cat === 'about' ? `/${doc.uid}` : `/stories/${doc.uid}`
            return (
              <CardWithImage
                key={`related-${index}`}
                color={color}
                title={doc.data.title}
                image={doc.data.featured_image}
                link={url}
              />
            )
          })}
        </ArticleWrap>
      </Container>
    </Wrapper>
  )
}

RelatedArticles.propTypes = {}

export default RelatedArticles
