import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import AgeBadges from '../elements/AgeBadges'
import CategoryPill from '../elements/CategoryPill'
import useWindowSize from '../../utils/useWindowSize'

const StyledBanner = styled.div`
  background-position: center;
  background-size: cover;
  position: relative;
  max-width: 100%;
  margin: 0 100px 30px;
  padding: 0;
  background-color: rgb(247, 247, 247);
  border-bottom: 4px solid rgb(170, 187, 204);
  padding-bottom: 15px;
  @media (min-width: 1700px) {
    max-width: 77%;
    align-self: center;
    min-width: 77%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin: 0 50px 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 0 25px 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    margin: 0 15px 15px;
    border-bottom: none;
    padding-bottom: 0;
  }
`
const Blurb = styled.div`
  width: 100%;
  color: black;
  display: flex;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;

  &.row {
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-end;
    justify-content: space-between;
    align-items: center;
  }

  h1 {
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 0;
    line-height: 1.2em;
    margin-top: 15px;
    color: rgb(18, 71, 122);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    margin-bottom: 10px;
    h1 {
      font-size: 28px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin-bottom: 25px;
    h1 {
      font-size: 30px;
      max-width: 80%;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin-bottom: 25px;
    h1 {
      font-size: 39px;
    }
  }
`
const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: none;
  width: 100%;
  margin: 0;
  padding: 0;
  align-items: flex-start;
  justify-content: space-between;
  /* @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 40px 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.l}) {
    padding: 60px 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  } */
`

const Pills = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    align-items: flex-start;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
  }
`
const PillsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  align-content: space-between;
  width: 100%;
`
const ArticleBanner = ({
  category,
  subcategories,
  subcatUid,
  title,
  color,
  catUid,
  excerpt,
  featured,
  ageOne,
  ageTwo,
  ageThree,
  ageFour
}) => {
  const isTablet = useWindowSize() < 800
  return (
    <StyledBanner>
      <FlexWrap>
        {isTablet ? (
          <>
            <PillsWrapper>
              <Pills>
                {subcategories[0]?.subcategory?.document &&
                  subcategories.map((subcat, index) => {
                    if (subcat.subcategory.document)
                      return (
                        <CategoryPill
                          key={`cat-${index}`}
                          uid={subcat.subcategory?.uid}
                          color={color}
                          name={subcat.subcategory.document?.data.name}
                        />
                      )
                    else return null
                  })}
              </Pills>
              <AgeBadges
                ageOne={ageOne}
                ageTwo={ageTwo}
                ageThree={ageThree}
                ageFour={ageFour}
              />
            </PillsWrapper>
            <Blurb className="row">
              {/*category && catUid !== "about" ? 
            <Link to={`/${catUid}`}>
              <span className="cat">{category}</span>
            </Link>
            : null
          */}
              <h1>{title}</h1>
            </Blurb>
          </>
        ) : (
          <>
            <Pills>
              {subcategories[0]?.subcategory?.document &&
                subcategories.map((subcat, index) => {
                  if (subcat.subcategory.document)
                    return (
                      <CategoryPill
                        key={`cat-${index}`}
                        uid={subcat.subcategory?.uid}
                        color={color}
                        name={subcat.subcategory.document?.data.name}
                      />
                    )
                  else return null
                })}
            </Pills>
            <Blurb className="row">
              {/*category && catUid !== "about" ? 
            <Link to={`/${catUid}`}>
              <span className="cat">{category}</span>
            </Link>
            : null
          */}
              <h1>{title}</h1>
              <AgeBadges
                ageOne={ageOne}
                ageTwo={ageTwo}
                ageThree={ageThree}
                ageFour={ageFour}
              />
            </Blurb>
          </>
        )}
      </FlexWrap>
    </StyledBanner>
  )
}

export default ArticleBanner
